'use client';

import { FC } from 'react';

import { IBodyTextProps } from 'components/atoms/BodyText/types';
import { BodyTextWrapper } from 'components/atoms/BodyText/styles';

const BodyText: FC<IBodyTextProps> = ({
  display,
  text,
  children,
  fontWeight,
  ellipsisText = false,
  size,
  color,
  ...restProps
}) => {
  return (
    <BodyTextWrapper
      size={size}
      display={display}
      title={text}
      className={`${size}`}
      color={color}
      fontWeight={fontWeight}
      data-ellipsistext={ellipsisText}
      {...restProps}
    >
      {text || children}
    </BodyTextWrapper>
  );
};

export default BodyText;
