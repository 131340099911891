'use client';

import Image from 'next/image';
import { FC } from 'react';

import { iconTypes } from './constants';
import { IIconProps } from './types';

const Icon: FC<IIconProps> = ({ name, width = 31, height = 14, ...props }) => {
  const IconComponent = iconTypes[name] || '';

  return (
    <Image
      src={IconComponent}
      width={width}
      height={height}
      alt={name}
      priority={false}
      {...props}
    />
  );
};

export default Icon;
