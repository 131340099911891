export enum HeadingEnum {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4'
}

export enum BodyTextEnum {
  base = 'base',
  large = 'large',
  small = 'small',
  extraLarge = 'extraLarge',
  extraSmall = 'extraSmall'
}

export enum FontWightEnum {
  bold = 'bold',
  medium = 'medium',
  regular = 'regular'
}

export enum LinkSizeEnum {
  small = 'small',
  base = 'base'
}

export enum ParagraphEnum {
  base = 'base',
  large = 'large',
  small = 'small',
  extraLarge = 'extraLarge',
  extraSmall = 'extraSmall',
  superLarge = 'superLarge'
}
