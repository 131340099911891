import Typography from 'antd/lib/typography';
import styled from 'styled-components';

import { IBodyTextProps } from 'components/atoms/BodyText/types';
import {
  FontWightEnum,
  BodyTextEnum
} from 'common/globalTypes/enums/typography';
import { typography } from 'styles/variables.module';

const { Text } = Typography;

//this function return font weight property change depending on the value change
export const BodyTextWrapper = styled(Text)<IBodyTextProps>`
  ${({
    size = BodyTextEnum.base,
    fontWeight = FontWightEnum.regular,
    display = 'block',
    linecalm = 1,
    color
  }) => `&& {
      font-size: ${typography.bodyText.size[size]};
      font-weight: ${typography.fontWeight[fontWeight]};
      line-height: ${typography.bodyText.lineHeight[size]};
      letter-spacing: ${typography.bodyText.letterSpacing[size]}};
      display: ${display};
      &.ant-typography {
        color: ${color}
      }
      
      &[data-ellipsistext='true'] {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${linecalm};
          display: -webkit-box;
          overflow: hidden;
          vertical-align: middle;
          width: 100%  
      }
  }`};
`;
